import React, { createContext, useState } from "react";
const AgencyContext = createContext({});

export const AgencyProvider = ({ children }) => {
  const [agencyNombre, setAgencyNombre] = useState("");
  return (
    <AgencyContext.Provider
      value={{
        agencyNombre,
        setAgencyNombre,
      }}
    >
      {children}
    </AgencyContext.Provider>
  );
};
export default AgencyContext;
