import React, { useState } from "react";

const KitItem = (props) => {
  if (props.item == props.objeto.ID) {
    console.log(props.objeto.PRODUCTO);
  }
  return (
    <div
      className={`kit-item ${props.item == props.objeto.ID ? "selected" : ""}`}
      onClick={() => props.selectItems(props.objeto.ID)}
    >
      <div>
        <p>{props.objeto.PRODUCTO}</p>
        {props.item == props.objeto.ID ? (
          <i className="fa-solid fa-check"></i>
        ) : (
          <i></i>
        )}
      </div>
      <div>
        <span> {props.objeto.DESCRIPCION}</span>
      </div>
    </div>
  );
};

export default KitItem;
