import axios from "axios";
import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import url_local from "../../config/config";
import Swal from "sweetalert2";
import { errorToast } from "../../common/Toast";
const CoordenadasButton = (props) => {
  const actualizarCoordenadas = async () => {
    Swal.fire({
      text: "Estas seguro que quieres actulizar las coordenadas?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: `No actualizar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigator.geolocation.getCurrentPosition(async function (position) {
          const nombre = reactLocalStorage.get("nombre", true);
          const data = {
            id: props.id,
            comercial: nombre,
            latitud: position.coords.latitude,
            longitud: position.coords.longitude,
          };
        props.loading(true);
         await handleCoords(data)
        });
      }
    });
  };
  const handleCoords = async (data)=>{
    try{
      console.log(data);
       await axios.post(url_local() + '/api/coordenadas', data);
       props.loading(false);
        Swal.fire('Exito al actualizar coordenadas', '', 'success').then(
          (result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          }
        );
    }catch(e){
        props.loading(false);
        errorToast('Ocurrio un error al actualizar!.')
    } 
  }

  return (
    <button onClick={() => actualizarCoordenadas()}>
      Actualizar coordenadas
    </button>
  );
};
export default CoordenadasButton;
