import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import login from "../../img/img.png";
import { Link, useNavigate } from "react-router-dom";
import "./Profile.css";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../Loader/Loader";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const email = reactLocalStorage.get("nombre", true);
  const navigate = useNavigate();

  if (email == "undefined") {
    navigate("/login");
  }

  setTimeout(function () {
    setLoading(false);
  }, 1000);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="profile-container">
            <div className="profile_container">
              <div className="profile_container_content">
                <img src={login} alt="" />
                <h3>{email}</h3>
              </div>
              <div className="profile-buttons">
                <a href="/" className="session-a">
                  <button onClick={() => reactLocalStorage.clear()}>
                    Cerrar sesión
                  </button>
                </a>
                <Link to={"/home"} className="session-B">
                  Volver al inicio
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
