import React, { useState } from 'react';
import FormTarea from '../../common/FormTarea/FormTarea';

const NuevaTareaDC = ({ coordenadas, usersUMT }) => {
  const idAgenciaDC = 4504752647;
  const coords = {
    lat: coordenadas.latitude,
    lng: coordenadas.longitude
  };
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="crear-tarea">
        <button onClick={openModal}>Crear Tarea DC</button>
      </div>
      <FormTarea
        isOpen={isOpen}
        onClose={onCloseModal}
        idCompany={idAgenciaDC}
        coordenadas={coords}
        usersUMT={usersUMT}
        agencia={'Decreditos SA'}
      />
    </>
  );
};

export default NuevaTareaDC;
