
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import Navbar from "../Navbar/Navbar";
import ContactForm from "./ContactForm";
import ResultadoVisita from "./ResultadoVistia";



const AddContacto = () => {

  const { id } = useParams();
  console.log(id);
  return (
    <>
         <Navbar/>
         <div style={{paddingTop:'80px'}}>
        <h1 style={{color:'#1D93C1', textAlign:'center', padding: '1rem', fontSize:'1.47rem'}}>Contactos de agencia</h1>
         <ContactForm/>
         </div>
    </>
  );
};
export default AddContacto;
