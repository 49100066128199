import React, { useState } from "react";
const FormularioTareas = (props) => {
  const [tareaOpenId, setTareaOpenId] = useState([]);
  const handleOpenBox = (id) => {
    var updatedList = [...tareaOpenId];
    if (!tareaOpenId.includes(id)) {
      updatedList = [...tareaOpenId, id];
    } else {
      updatedList.splice(tareaOpenId.indexOf(id), 1);
    }
    setTareaOpenId(updatedList);
    console.log(tareaOpenId);
  };
  const handleCheck = (event) => {
    var updatedList = [...props.tareaId];
    if (event.target.checked) {
      updatedList = [...props.tareaId, event.target.value];
    } else {
      updatedList.splice(props.tareaId.indexOf(event.target.value), 1);
    }
    props.setTareaId(updatedList);
    console.log(props.tareaId);
    console.log(props.tareaId.length);
  };
  return (
    <div className="tareas-container">
      <h2 className="form-title">{props.tareas.length != 0 ? "Tareas" : ""}</h2>
      {props.tareas
        ? props.tareas.map((tarea) => {
            return (
              <div className="task-cotnainer">
                <div key={tarea.id} className="tareas-item-container">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    value={tarea.id}
                    onChange={handleCheck}
                    className="tareas-input"
                  />
                  <div>
                    <div>
                      <h3>Titulo</h3>
                      <p>{tarea.titulo}</p>
                    </div>
                    <div>
                      <h3>Prioridad</h3>
                      <small
                        className={(() => {
                          switch (tarea.prioridad) {
                            case (tarea.prioridad = "HIGH"):
                              return "Alta";
                            case (tarea.prioridad = "MEDIUM"):
                              return "Mediana";
                            case (tarea.prioridad = "LOW"):
                              return "Baja";
                            default:
                              return tarea.prioridad;
                          }
                        })()}
                      >
                        {" "}
                        {(() => {
                          switch (tarea.prioridad) {
                            case (tarea.prioridad = "HIGH"):
                              return "Alta";
                            case (tarea.prioridad = "MEDIUM"):
                              return "Mediana";
                            case (tarea.prioridad = "LOW"):
                              return "Baja";
                            default:
                              return tarea.prioridad;
                          }
                        })()}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="tarea-body">
                  <p className={tareaOpenId.includes(tarea.id) ? "open" : ""}>
                    {tarea.cuerpo
                      ? tarea.cuerpo.replace("<p>", "").replace("</p>", "")
                      : "No hay descripcion de la tareas"}
                  </p>
                  {tareaOpenId.includes(tarea.id) ? (
                    <i
                      className="fa-solid fa-chevron-up"
                      onClick={() => handleOpenBox(tarea.id)}
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-chevron-down"
                      onClick={() => handleOpenBox(tarea.id)}
                    ></i>
                  )}
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default FormularioTareas;
