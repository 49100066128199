import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AgenciaBox from "./AgenciaBox";
import "./Agencias.css";
import UserContext from "../../context/UserContext";
import { reactLocalStorage } from "reactjs-localstorage";
import Navbar from "../Navbar/Navbar";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router";
import TaskData from "./TaskData";
import url_local from "../../config/config";
import ErrorUbicacion from "../ErrorUbicacion/ErrorUbicacion";
import SearchAgencias from "./SearchAgencias";
import { toast, ToastContainer } from "react-toastify";
import Popup from '../../common/Pop-up/Popup';
import Cookies from "js-cookie";
import NuevaTareaDC from './NuevaTareaDC';
import { errorToast } from '../../common/Toast';
const AgenciaContainer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [empresaFiltrar, setEmpresaFiltrar] = useState([]);
  const [loadingAgencias, setLoadingAgencias] = useState(true);
  const [loadingTareas, setLoadingTareas] = useState(true);
  const [tarea, setTarea] = useState([]);
  const [filter, setFilter] = useState("Todas");
  const [orden, setOrden] = useState("Cercana");
  const [errorUbicacion, setErrorUbicacion] = useState(false);
  const [visitar, setVisitar] = useState(false);
  const [sinVisitar, setSinVisitar] = useState(false);
  const [lejos, setLejos] = useState(false);
  const [cerca, setCerca] = useState(true);
  const [estado, setEstado] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [coords, setCoords] = useState({});
  const [usersUMT, setUsersUMT] = useState([]);

   const handlePopupClose = () => {
     setShowPopup(false);
     reactLocalStorage.set('[Show PopUp]', false);
   };

  const notify = (text) =>
    toast.error(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const getAgencias = async () => {
    setShowPopup(JSON.parse(reactLocalStorage.get('[Show PopUp]', false)));
    if (setLoading) {
      try {
        const nombre = reactLocalStorage.get("nombre", true);
        const clave = reactLocalStorage.get("clave", true);
        if (nombre == true || clave == true) {
          navigate("/");
        }
        if (Cookies.get("estado") == undefined) {
          Cookies.set("estado", null, { expires: 1 });
          setEstado(null);
        } else {
          setEstado(Cookies.get("estado"));
        }
        if (Cookies.get("cerca") == undefined) {
          Cookies.set("cerca", true, { expires: 1 });
        }
        if (Cookies.get("visitar") == undefined) {
          Cookies.set("visitar", false, { expires: 1 });
        }
        if (Cookies.get("sinVisitar") == undefined) {
          Cookies.set("sinVisitar", false, { expires: 1 });
        }
        if (Cookies.get("cerca") == "true") {
          setOrden("Cercana");
          setCerca(true);
          setLejos(false);
        } else {
          setOrden("Lejanas");
          setCerca(false);
          setLejos(true);
        }
        if (
          Cookies.get("visitar") == "false" &&
          Cookies.get("sinVisitar") == "false"
        ) {
          setFilter("Todas");
          setVisitar(false);
          setSinVisitar(false);
        } else if (Cookies.get("visitar") == "true") {
          setFilter("Visitado");
          setVisitar(true);
          setSinVisitar(false);
        } else {
          setFilter("Sin visitar");
          setVisitar(false);
          setSinVisitar(true);
        }

        navigator.geolocation.getCurrentPosition(
          async function (position) {
            const agencia = reactLocalStorage.get("nombreagencia");
            if (agencia) {
              notify(
                "Usted tiene un visita iniciada en " +
                  agencia +
                  " no se olvide de completarla"
              );
            }
            const clave = reactLocalStorage.get("clave", true);
           
            setCoords( position.coords);
            const ubicacion = {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
              id: clave,
              visitar: Cookies.get("visitar"),
              sinVisitar: Cookies.get("sinVisitar"),
              cerca: Cookies.get("cerca"),
              estado: Cookies.get("estado"),
            };
            try {
              await getUsersUMT()
              const response = await axios.post(
                url_local() + "/api/graphqlhome",
                ubicacion
              );
              setEmpresaFiltrar(response.data.empresa);
              setLoadingAgencias(false);
              setLoading(false);
              setTarea(response.data.tareas);
              setTareasAmount(response.data.tareas.length);
              setLoadingTareas(false);
            } catch (error) {
              console.log(error);
            }
          },
          function error() {
            setLoading(false);
            setErrorUbicacion(true);
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getUsersUMT = async () => {
    try {
      const usersUmt = await axios.get(url_local() + '/api/users-utm');
      setUsersUMT(usersUmt.data);
    } catch (e) {
      setUsersUMT([]);
      errorToast('Ocurrio un error al consultar los usuarios en UMT');
    }
  };
  useEffect(() => {
    getAgencias();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const { tareasPage, setTareasPage, setTareasAmount } =
    useContext(UserContext);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [tareasPage]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : errorUbicacion ? (
        <ErrorUbicacion />
      ) : (
        <>
          {showPopup && <Popup onClose={handlePopupClose} />}
          <Navbar />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="flex">
            <div className={`agencias-page ${tareasPage ? "close" : ""}`}>
              <SearchAgencias
                setCerca={setCerca}
                setLejos={setLejos}
                setSinVisitar={setSinVisitar}
                setVisitar={setVisitar}
                setEstado={setEstado}
                cerca={cerca}
                lejos={lejos}
                sinVisitar={sinVisitar}
                visitar={visitar}
                estado={estado}
                setEmpresaFiltrar={setEmpresaFiltrar}
                setLoadingAgencias={setLoadingAgencias}
                setFilter={setFilter}
                setOrden={setOrden}
                filter={filter}
                orden={orden}
                loadingAgencias={loadingAgencias}
              />
              <NuevaTareaDC coordenadas={coords} usersUMT={usersUMT}/>
              <div className="Agencia-container">
                {loadingAgencias ? (
                  <Loader />
                ) : empresaFiltrar !== undefined ? (
                  empresaFiltrar.length > 0 ? (
                    empresaFiltrar.map((empresa, key) => {
                      return <AgenciaBox f={empresa} key={key} />;
                    })
                  ) : (
                    <h2 className="agencia-not-found">
                      No hay agencias disponibles
                    </h2>
                  )
                ) : (
                  () => getAgencias()
                )}
              </div>
            </div>
            <div
              className={`tareas-section-container ${tareasPage ? "open" : ""}`}
            >
              {tarea ? (
                <div className="volver-atras-flecha">
                  <i
                    className="fa-solid fa-chevron-left left-arrow"
                    onClick={() => setTareasPage(!tareasPage)}
                  ></i>
                  <h2 className="tareas-title">Tareas</h2>
                </div>
              ) : (
                ""
              )}
              <div className="Task-section-container">
                {loadingTareas ? (
                  <Loader />
                ) : tarea.length > 0 ? (
                  tarea.map((task, index) => {
                    return (
                      <TaskData
                        latitude={coords?.latitude}
                        longitude={coords?.longitude}
                        task={task}
                        key={index}
                      />
                    );
                  })
                ) : (
                  <div>
                    <h4 className="no-hay-tareas">
                      Actualmente no hay tareas asignadas a tu usuario
                    </h4>
                    <p
                      onClick={() => setTareasPage(!tareasPage)}
                      className="volver-atras"
                    >
                      <i className="fa-solid fa-arrow-left"></i> Volver atras
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AgenciaContainer;
