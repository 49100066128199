import React from "react";
import "./Succes.css";
import succes from "../../img/success.png";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
const Succes = () => {
  return (
    <>
      <Navbar />
      <div className="exito-container">
        <img src={succes} alt="" srcSet="" />
        <div>
          <h3>¡Solicitud exitosa!</h3>
          <h4>Se enviará el producto seleccionado en los próximos días.</h4>
        </div>
        <button className="link-container">
          <Link className="link-container" to={"/home"}>
            Ir al inicio
          </Link>{" "}
        </button>
      </div>
    </>
  );
};

export default Succes;
