import React, { useState, useEffect } from "react";
const Editar = (props) => {
  const [calle, setCalle] = useState(props.data.direccion_calle);
  const [numero, setNumero] = useState(props.data.direccion_numero);
  const [piso, setPiso] = useState(props.data.direccion_piso);
  const [dpto, setDpto] = useState(props.data.direccion_depto);
  const [localidad, setLocalidad] = useState(props.data.direccion_localidad);
  const [codigoPostal, setCodigoPostal] = useState(
    props.data.direccion_codigo_postal
  );
  const [provincia, setProvincia] = useState(props.data.direccion_provincia);
  const [indicacionesAdicionales, setIndicacionesAdicionales] = useState(null);
  const data = {
    calle: calle,
    numero: numero,
    piso: piso,
    dpto: dpto,
    localidad: localidad,
    codigoPostal: codigoPostal,
    provincia: provincia,
    indicacionesAdicionales: indicacionesAdicionales,
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [props.editarEstado, props.pedido]);

  return (
    <div
      className={`editar-container ${
        props.editarEstado && props.pedido == false ? "open" : ""
      }`}
    >
      <div className="editar-input-box">
        <h4>Calle</h4>
        <input
          type="text"
          value={calle}
          onChange={(e) => setCalle(e.target.value)}
        />
      </div>
      <div className="editar-input-box">
        <h4>Número</h4>
        <input
          type="number"
          value={numero}
          onChange={(e) => setNumero(e.target.value)}
        />
      </div>
      <div className="editar-doble-input">
        <div>
          <h4>Piso</h4>
          <input
            type="number"
            value={piso}
            onChange={(e) => setPiso(e.target.value)}
          />
        </div>
        <div>
          <h4>Dpto</h4>
          <input
            type="text"
            value={dpto}
            onChange={(e) => setDpto(e.target.value)}
          />
        </div>
      </div>
      <div className="editar-input-box">
        <h4>Localidad</h4>
        <input
          type="text"
          value={localidad}
          onChange={(e) => setLocalidad(e.target.value)}
        />
      </div>
      <div className="editar-input-box">
        <h4>Codigo</h4>
        <input
          type="number"
          value={codigoPostal}
          onChange={(e) => setCodigoPostal(e.target.value)}
        />
      </div>
      <div className="editar-input-box">
        <h4>Provincia</h4>
        <input
          type="text"
          value={provincia}
          onChange={(e) => setProvincia(e.target.value)}
        />
      </div>
      <div className="indicaciones-container">
        <h4>Indicaciones adicionales</h4>
        <textarea
          name=""
          id=""
          value={indicacionesAdicionales}
          onChange={(e) => setIndicacionesAdicionales(e.target.value)}
        ></textarea>
      </div>
      <p>Recordá que esta información tiene caracter de declaración jurada.</p>
      <button onClick={() => props.pedidoEstado(data)}>Guardar</button>
    </div>
  );
};

export default Editar;
