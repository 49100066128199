import React from "react";
import Moment from "moment";
import tareas from "../../img/tareas2.png";
import hable from "../../img/person.png";
const ReunionesInformacion = ({ reunion }) => {
  let fecha = Moment(reunion.hs_meeting_end_time).format("MM-DD-YYYY");
  //console.log(fecha)
  return (
    <div className="reuniones_container">
      <h2>
        {reunion.hs_meeting_end_time
          ? "Reunión - Visita " +
            fecha.slice(3, 6) +
            fecha.slice(0, 3) +
            fecha.slice(6, 10)
          : "Reunión - visita sin fecha"}
      </h2>
      <div className="reunion_container_content">
        <h3>
          {reunion.associations.contact_collection__engagement_to_contact
            .items[0]
            ? reunion.associations.contact_collection__engagement_to_contact
                .items[0].firstname +
              " " +
              reunion.associations.contact_collection__engagement_to_contact
                .items[0].lastname
            : "No hay contacto asignado"}
        </h3>
        <div className="reunion-info-container">
          <div className="reunion_info">
            <img src={hable} alt="" />
            <p>Visitado por: </p>
            <h4>
              {reunion.hubspot_owner_id.firstname
                ? reunion.hubspot_owner_id.firstname +
                  " " +
                  reunion.hubspot_owner_id.lastname
                : "No hay un comercial asignadox"}
            </h4>
          </div>
          <div className="reunion_info">
            <img src={tareas} alt="" />
            <p>Resultado: </p>
            <h4>
              {(() => {
                switch (reunion.hs_meeting_outcome.value) {
                  case (reunion.hs_meeting_outcome.value = "COMPLETED"):
                    return "Completada";
                  case (reunion.hs_meeting_outcome.value = "NO_SHOW"):
                    return "No se presento";
                  case (reunion.hs_meeting_outcome.value = "CANCELED"):
                    return "Cancelada";
                  default:
                    return "No hay resultado de la reunion";
                }
              })()}
            </h4>
          </div>
        </div>
        <div className="desc-reunion-container">
          <h4>Descripcion del asistente: </h4>

          <p>
            {reunion.hs_meeting_body
              ? reunion.hs_meeting_body.replace("<p>", "").replace("</p>", "")
              : "No hay descripcion de la reunion"}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ReunionesInformacion;
