import React, { useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import _uniqBy from "lodash/uniqBy";
import url_local from "../../config/config";
import axios from "axios";
import "./Formulario.css";

const Comentarios = (props) => {
  const [usersUMT, setUsersUMT] = useState([]);
  const [comentario2, setComentario2] = useState("");

  const handleComentario = (e) => {
    const textoTransformado = e.target.value.replace(/\@\[\@(.*?)\]\((.*?)\)/, '$2, @$1');
    setComentario2(e.target.value);
    props.setComentario(textoTransformado);
    props.dataMail(textoTransformado);
  };

/*{const handleMention = (e) => {
    setMention(userMentionData);
    props.dataMention(userMentionData);
  };}*/

  useEffect(() => {
    const getUsersUMT = async () => {
      try {
        const usersUmt = await axios.get(url_local() + "/api/users-utm");
        setUsersUMT(usersUmt.data);
      } catch (e) {
        console.log("Ocurrió un error al consultar los usuarios en UMT");
      }
    };
    getUsersUMT();
  }, []);

  const userMentionData = usersUMT.map((myUser) => ({
    id: myUser.email,
    display: `@${myUser.name} ${myUser.lastname}`,
  }));

  //console.log("aca", usersUMT);
  //console.log("Comentario", comentario);

  return (
    <div className="comentarios_forms">
      <h3>
        Comentarios <small>*</small>
      </h3>
      <MentionsInput
        value={comentario2}
        onChange={handleComentario}
        placeholder="Use el símbolo @ para mencionar usuarios"
        style={customStyle}
        markup="@[__display__](__id__)"
      >
        <Mention
          type="user"
          trigger="@"
          data={userMentionData}
          //markup="__id__, __display__"
        />
      </MentionsInput>
    </div>
  );
};

const customStyle = {
  input: {
    height: 140,
    overflow: "auto",
    border: "none",
    padding: "8px",
  },
  highlighter: {
    height: 140,
    overflow: "hidden",
    boxSizing: "border-box",
  },
};

export default Comentarios;