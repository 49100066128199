import React, { createContext, useState } from "react";
const UserContext = createContext({});
export const UserProvider = ({ children }) => {
  const [userNombre, setUserNombre] = useState();
  const [userContra, setUserContra] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userTelefono, setUserTelefono] = useState();
  const [userDni, setUserDni] = useState();
  const [userId, setUserId] = useState();
  const [userEmpresas, setUserEmpresas] = useState();
  const [tareasPage, setTareasPage] = useState(false);
  const [tareasAmount, setTareasAmount] = useState([]);
  return (
    <UserContext.Provider
      value={{
        userNombre,
        userContra,
        userEmail,
        userTelefono,
        userDni,
        userId,
        userEmpresas,
        tareasPage,
        tareasAmount,
        setTareasAmount,
        setUserNombre,
        setUserContra,
        setUserEmail,
        setUserTelefono,
        setUserDni,
        setUserId,
        setUserEmpresas,
        setTareasPage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export default UserContext;
