import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import url_local from "../../config/config";
import { useParams } from "react-router";

const ContactForm = () => {

  const { companyId } = useParams();

  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    telefono: "",
    email: "",
    cargo: "",
  });

  const cargos = [
    {
      nombre: "Vendedor",
    },
    {
      nombre: "Encargado",
    },
    {
      nombre: "Socio",
    },
    {
      nombre: "Particular",
    },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(url_local() + '/api/addcontact/' + companyId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    const result = await response.json();
    console.log(result);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          margin: "1rem 1rem 1rem 1rem",
        }}
      >
        <TextField
          required
          name="nombre"
          value={formData.nombre}
          onChange={handleChange}
          id="outlined-required"
          label="Nombre"
          placeholder="Nombre"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1A95D3",
              },
              "&:hover fieldset": {
                borderColor: "#1A95D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4fc4ff",
              },
            },
          }}
        />
        <TextField
          required
          name="apellido"
          value={formData.apellido}
          onChange={handleChange}
          id="outlined-required"
          label="Apellido"
          placeholder="Apellido"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1A95D3",
              },
              "&:hover fieldset": {
                borderColor: "#1A95D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4fc4ff",
              },
            },
          }}
        />
        <TextField
          required
          name="dni"
          value={formData.dni}
          onChange={handleChange}
          id="outlined-required"
          label="DNI"
          placeholder="DNI"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1A95D3",
              },
              "&:hover fieldset": {
                borderColor: "#1A95D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4fc4ff",
              },
            },
          }}
        />
        <TextField
          required
          name="telefono"
          value={formData.telefono}
          onChange={handleChange}
          id="outlined-required"
          label="Telefono"
          placeholder="Telefono"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1A95D3",
              },
              "&:hover fieldset": {
                borderColor: "#1A95D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4fc4ff",
              },
            },
          }}
        />
        <TextField
          required
          name="email"
          value={formData.email}
          onChange={handleChange}
          id="outlined-required"
          label="Email"
          placeholder="Ejemplo@decreditos.com"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1A95D3",
              },
              "&:hover fieldset": {
                borderColor: "#1A95D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4fc4ff",
              },
            },
          }}
        />
        <TextField
          name="cargo"
          value={formData.cargo}
          onChange={handleChange}
          id="outlined-select-currency"
          select
          label="Select"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1A95D3",
              },
              "&:hover fieldset": {
                borderColor: "#1A95D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4fc4ff",
              },
            },
          }}
        >
          {cargos.map((option) => (
            <MenuItem key={option.nombre} value={option.nombre}>
              {option.nombre}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1rem', margin: '1rem 1rem 1rem 1rem' }}>
        <Button variant="outlined" >Volver</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<PersonAddIcon />}
        >
          Agregar
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;

