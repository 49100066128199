import React from "react";
import user from "../../img/user-solid 3.png";
import userDueño from "../../img/userDueño.png";
import { Icon } from "@iconify/react";
import ContactosSlider from "./ContactosSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useEffect } from "react";
import Visitabuttons from "./Visitabuttons";
const ContactosSection = (props) => {
  let contacto = props.contactos.filter((item) => item.email == props.contactosIndex);
  useEffect(() => {
  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
 
  }, [props.contactosSection]);
  if (contacto.length>0) {
    return (
      <div
        className={`contact-page-container ${
          props.contactosSection ? "open" : ""
        }`}
      >
        <div className="contacto-header-container">
          <img
            src={
              contacto.length>0
                ? contacto[0].cargo_sistema?.label == "Empleado"
                  ? user
                  : userDueño
                : ""
            }
            alt=""
          />
          <div>
            <p>
              {" "}
              {contacto.length>0
                ? contacto[0].firstname.indexOf(" ") !== -1
                  ? contacto[0].firstname.slice(0, contacto[0].firstname.indexOf(" "))
                  : contacto[0].firstname
                : ""}{" "}
              {contacto[0].lastname!==null ? contacto[0].lastname.slice(0, 1) + "." : ""}
            </p>
            {contacto ? (
              <p className="contacto-cargo">
                {contacto[0].cargo_sistema?.label == "Empleado"
                  ? "Vendedor"
                  : contacto[0].cargo_sistema?.label}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {contacto ? (
          <div className="contacto-info-container">
            {contacto[0].email!==null ? (
              <div>
                <Icon
                  icon="ic:baseline-mail-outline"
                  color="#1D93C1"
                  width="25"
                  height="25"
                />
                <p>
                  <span>Email:</span>
                  {contacto[0].email}
                </p>
              </div>
            ) : (
              <div>
                <Icon
                  icon="ic:baseline-mail-outline"
                  color="#1D93C1"
                  width="25"
                  height="25"
                />
                <p>
                  <span>Email:</span> No tiene mail
                </p>
              </div>
            )}
            {contacto[0].phone!==null ? (
              <div>
                <Icon
                  icon="ic:baseline-phone-android"
                  color="#1D93C1"
                  width="25"
                  height="25"
                />
                <p>
                  <span>Telefono:</span>
                  {contacto[0].phone}
                </p>
              </div>
            ) : (
              <div>
                <Icon
                  icon="ic:baseline-phone-android"
                  color="#1D93C1"
                  width="25"
                  height="25"
                />
                <p>
                  <span>Telefono:</span> No tiene telefono
                </p>
              </div>
            )}
  
            {contacto[0].dni!==null ? (
              <div>
                <Icon
                  icon="mdi:id-card-outline"
                  color="#1D93C1"
                  width="25"
                  height="25"
                />
                <p>
                  <span>DNI:</span>
                  {contacto[0].dni}
                </p>
              </div>
            ) : (
              <div>
                <Icon
                  icon="mdi:id-card-outline"
                  color="#1D93C1"
                  width="25"
                  height="25"
                />
                <p>
                  <span>DNI:</span>No tiene DNI
                </p>
              </div>
            )}
          </div>
        ) : (
          ""
        )}

          {props.contactos.filter((item) => item.email !== contacto[0].email).length>0?
             <div className="equipos-container">
              <h2>Dueños y Vendedores</h2>
          <Swiper
            navigation={false}
            slidesPerView={3.5}
            spaceBetween={6}
            modules={Navigation}
            className="mySwiper"
          >
          
            {props.contactos
              ? props.contactos.filter((item) => item.email !== contacto[0].email)
              .sort((a, b) => {
                if (a.cargo_sistema?.value === 'dueno') {
                  return -1;
                } else if (b.cargo_sistema?.value === 'empleado') {
                  return 1;
                }else{
                  return 0 
                }
              })
              .map((contacto, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ContactosSlider
                        contactosSection={props.contactosSection}
                        setContactosSection={props.setContactosSection}
                        setContactosIndex={props.setContactosIndex}
                        index={index}
                        contacto={contacto}
                      />
                    </SwiperSlide>
                  );
                })
              : ""}
          </Swiper>
        </div>
        :""}
        {/* <ContactoButton id={props.id}/> */}
        <Visitabuttons 
        empresa={props.empresa}
        id={props.id}
        longitude={props.longitude}
        latitude={props.latitude}
        />
      </div>
    );
  }
 
};

export default ContactosSection;
