import React, { useContext, useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../img/LogoFooter.png";
import url_local from "../../config/config";
import { errorToast } from '../../common/Toast';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
const Login = () => {
  const { userNombre, setUserNombre, userContra, setUserContra } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const userCheck = () => {
    const nombre = reactLocalStorage.get("nombre", true);
    const clave = reactLocalStorage.get("clave", true);
    if (nombre != true && clave != true) {
      navigate("/home");
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    userCheck();
  }, []);
  const handlenombre = (event) => {
    setUserNombre(event.target.value);
  };
  const handleClave = (event) => {
    setUserContra(event.target.value);
  };

  setTimeout(function () {
    setLoading(false);
  }, 1000);

   const togglePasswordVisibility = () => {
     setShowPassword(!showPassword);
   };

  const verificar = async (nombre, clave) => {
    if (nombre && clave) {
      try {
         const res = await axios.post(url_local() + '/api/login', {
           user: nombre.toString(),
           password: clave.toString(),
         });
         if(res.data.id && res.data.email){
           reactLocalStorage.set('nombre', res.data.email);
           reactLocalStorage.set('clave', res.data.id);
           reactLocalStorage.set('[Show PopUp]', true);
           navigate("/home");
         }else{
          errorToast('Error, al obetner los datos');
         }
      } catch (error) {
        errorToast('Error, Verificar usario y contraseña');
      }
    } else {
      errorToast('Error, Verificar usario y contraseña');
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="login">
            <img src={logo} alt="" />
            <div className="container_title">
              <h1>Web Comerciales</h1>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </div>
            <div className="container_content">
              <div className="container_content_form">
                <div className="formlog">
                  <div className="form_label">
                    <label htmlFor="">Ingrese su Usuario</label>
                    <input
                      type="text"
                      id="usuario"
                      name="usuario"
                      onChange={handlenombre}
                    />
                  </div>
                  <div className="form_label">
                    <label htmlFor="">Ingrese su Clave</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="clave"
                        name="clave"
                        onChange={handleClave}
                        style={{ paddingRight: '40px' }}
                      />
                      <button
                        onClick={togglePasswordVisibility}
                        style={{
                          marginLeft: '-30px', 
                          border: 'none',
                          background: 'transparent',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center', 
                          marginBotton: 0
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </button>
                    </div>
                  </div>
                  <div className="form_button">
                    <button
                      type="submit"
                      className="ingresar"
                      onClick={() => verificar(userNombre, userContra)}
                    >
                      Ingresa
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
