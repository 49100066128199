import React, { useState } from "react";
const EmailButton = (props) => {
  console.log(props);
  return (
    <button
      className={`email-button ${props.Email ? "open" : ""}`}
      onClick={() => props.setEmail(!props.Email)}
    >
      {props.Email ? "Cancelar Mail" : "Mail Maca"}
    </button>
  );
};

export default EmailButton;
