import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./index.css";
import Login from "./components/Login/Login";
import { UserProvider } from "./context/UserContext";
import { AgencyProvider } from "./context/AgencyContext";
import MasInformacion from "./components/DetailAgencias/MasInformacion";
import Profile from "./components/Profile/Profile";
import { reactLocalStorage } from "reactjs-localstorage";
import AgenciaContainer from "./components/Agencias/AgenciasContainer";
import Formulario from "./components/Formulario/Formulario";
import VerificarDireccion from "./components/Formulario/Urbano/VerificiarDireccion";
import Succes from "./components/Success/Succes";
import AddContacto from "./components/Formulario/AddContacto";

const App = () => {
  let logged = reactLocalStorage.get("nombre", true).length;

  return (
    <>
      <UserProvider>
        <AgencyProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<AgenciaContainer />} />
              <Route
                path="/login"
                element={logged > 11 ? <AgenciaContainer /> : <Login />}
              />
              <Route path="/agencia/:id" element={<MasInformacion />} />
              <Route
                path="/profile"
                element={
                  logged > 11 ? <Profile /> : <Navigate replace to={"/login"} />
                }
              />
              <Route path="/formulario/:id" element={<Formulario />} />
              <Route
                path="/contacto-nuevo/:companyId"
                element={<AddContacto />}
              />
              <Route path="/urbano/:id" element={<VerificarDireccion />} />
              <Route path="/success" element={<Succes />} />
            </Routes>
          </Router>
        </AgencyProvider>
      </UserProvider>
    </>
  );
};

export default App;

//<Route path='/profile' element={logged > 1 ? <Profile/> : <Navigate replace to={"/"}/>}/>
