import React, { useState } from "react";
const Verificar = (props) => {
  const data = {
    calle: props.data.direccion_calle,
    numero: props.data.direccion_numero,
    piso: props.data.direccion_piso,
    dpto: props.data.direccion_depto,
    localidad: props.data.direccion_localidad,
    codigoPostal: props.data.direccion_codigo_postal,
    provincia: props.data.direccion_provincia,
    indicacionesAdicionales: null,
  };
  return (
    <div
      className={`verificar-container ${
        props.editarEstado || props.pedido ? "open" : ""
      }`}
    >
      <div className="verificar-title">
        <h2>Verificación de domicilio</h2>
        <h3>
          Para el envío del merchandising vamos a necesitar validar el
          domicilio.
        </h3>
      </div>
      <div className="verificar-direcciones">
        <div className="direcciones-container">
          <div className="calle-container">
            <p>Calle:</p>
            <span>{props.data.direccion_calle}</span>
          </div>
          <div>
            <div>
              <p>Numero:</p>
              <span>{props.data.direccion_numero}</span>
            </div>
            <div>
              <p>Barrio/Localidad:</p>
              <span>{props.data.direccion_localidad}</span>
            </div>
          </div>
          <div>
            <div>
              <p>Provincia: </p>
              <span>{props.data.direccion_provincia}</span>
            </div>
            <div>
              <p>Codigo Postal:</p>
              <span>{props.data.direccion_codigo_postal}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="validar-buttons-container">
        <h4>¿Es correcto?</h4>
        <div>
          <button onClick={() => props.editar()}>No, editar</button>
          <button onClick={() => props.pedidoEstado(data)}>Sí, validar</button>
        </div>
      </div>
    </div>
  );
};
export default Verificar;
