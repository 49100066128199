import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import url_local from "../../config/config";
const SearchAgencias = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [filtrosAcoordion, setFiltrosAcoordion] = useState(false);
  const [cercaniaAcoordion, setCercaniaAcoordion] = useState(false);
  const [estadosAcoordion, setEstadosAcoordion] = useState(false);
  const [busqueda, setBusqueda] = useState(false);
  const peticion = async () => {
    setBusqueda(false);
    setSearchValue("");
    console.log("sin visitar");
    navigator.geolocation.getCurrentPosition(async function (position) {
      const nombre = reactLocalStorage.get("nombre", true);
      const clave = reactLocalStorage.get("clave", true);
      const ubicacion = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        visitar: props.visitar,
        sinVisitar: props.sinVisitar,
        cerca: props.cerca,
        estado: props.estado,
        id: clave,
      };
      const response = await axios.post(
        url_local() + "/api/graphql",
        ubicacion
      );
      console.log(response.data);
      props.setEmpresaFiltrar(response.data);
      props.setLoadingAgencias(false);
    });
  };
  const handleBusqueda = async () => {
    setBusqueda(true);
    props.setLoadingAgencias(true);
    props.setFilter("Todas");
    props.setOrden("Cercana");
    props.setCerca(true);
    props.setLejos(false);
    props.setSinVisitar(false);
    props.setVisitar(false);
    props.setEstado(null);
    Cookies.set("visitar", false, { expires: 1 });
    Cookies.set("sinVisitar", false, { expires: 1 });
    Cookies.set("cerca", true, { expires: 1 });
    Cookies.set("estado", null, { expires: 1 });
    navigator.geolocation.getCurrentPosition(async function (position) {
      const ubicacion = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        name: searchValue,
      };
      const nombre = reactLocalStorage.get("nombre", true);
      const clave = reactLocalStorage.get("clave", true);
      const response = await axios.post(
        url_local() + "/api/nombre/" + nombre + "/" + clave,
        ubicacion
      );
      console.log(response.data.empresas);
      props.setEmpresaFiltrar(response.data.empresas);
      props.setLoadingAgencias(false);
    });
  };
  useEffect(() => {
    if (props.loadingAgencias == true && busqueda == false) {
      peticion();
    }
  }, [props.visitar, props.sinVisitar, props.lejos, props.cerca, props.estado]);
  const handleEstado = (estado) => {
    props.setEstado(estado);
    setEstadosAcoordion(false);
    props.setLoadingAgencias(true);
    Cookies.set("estado", estado, { expires: 1 });
  };
  const handleDistancia = (cerca, lejos, orden) => {
    props.setCerca(cerca);
    props.setLejos(lejos);
    props.setOrden(orden);
    setCercaniaAcoordion(false);
    props.setLoadingAgencias(true);
    Cookies.set("cerca", cerca, { expires: 1 });
  };
  const handleVisitas = (visitar, filter, sinVisitar) => {
    props.setVisitar(visitar);
    props.setFilter(filter);
    props.setSinVisitar(sinVisitar);
    Cookies.set("visitar", visitar, { expires: 1 });
    Cookies.set("sinVisitar", sinVisitar, { expires: 1 });
    if (props.lejos == false) {
      props.setCerca(true);
    }
    setFiltrosAcoordion(false);
    props.setLoadingAgencias(true);
  };
  const handleKeyDown = (event) => {
    if (event.key == "Enter") {
      handleBusqueda();
    }
  };
  return (
    <div className="search-container">
      <div className="Navbar-Search">
        <input
          type="search"
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
          value={searchValue}
          placeholder="Buscar por nombre"
          className="search-input"
        />
      </div>
      <div className="filtros-home-container">
        <div className="filtros-select-box">
          <h3 onClick={() => setFiltrosAcoordion(!filtrosAcoordion)}>
            {" "}
            {props.filter}{" "}
            {filtrosAcoordion ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </h3>
          <div className={`filter ${filtrosAcoordion ? "open" : ""}`}>
            {!props.sinVisitar && !props.visitar ? (
              ""
            ) : (
              <button onClick={() => handleVisitas(false, "Todas", false)}>
                Todas
              </button>
            )}
            {props.sinVisitar ? (
              ""
            ) : (
              <button onClick={() => handleVisitas(false, "Sin visitar", true)}>
                Sin Visitar
              </button>
            )}
            {props.visitar ? (
              ""
            ) : (
              <button onClick={() => handleVisitas(true, "Visitado", false)}>
                Visitado
              </button>
            )}
          </div>
        </div>
        <div className="filtros-select-box">
          <h3 onClick={() => setCercaniaAcoordion(!cercaniaAcoordion)}>
            {" "}
            {props.orden}{" "}
            {cercaniaAcoordion ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </h3>
          <div className={`filter ${cercaniaAcoordion ? "open" : ""}`}>
            {props.lejos ? (
              ""
            ) : (
              <button onClick={() => handleDistancia(false, true, "Lejanas")}>
                Lejanas
              </button>
            )}
            {props.cerca ? (
              ""
            ) : (
              <button onClick={() => handleDistancia(true, false, "Cercanas")}>
                Cercanas
              </button>
            )}
          </div>
        </div>
        <div className="filtros-select-box">
          <h3 onClick={() => setEstadosAcoordion(!estadosAcoordion)}>
            {" "}
            {props.estado == "null" || props.estado == null
              ? "Estados"
              : props.estado}{" "}
            {estadosAcoordion ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </h3>
          <div className={`filter ${estadosAcoordion ? "open" : ""}`}>
            {props.estado !== "AA" ? (
              <button onClick={() => handleEstado("AA")}>AA</button>
            ) : (
              ""
            )}
            {props.estado !== "SA" ? (
              <button onClick={() => handleEstado("SA")}>SA</button>
            ) : (
              ""
            )}
            {props.estado !== "SC" ? (
              <button onClick={() => handleEstado("SC")}>SC</button>
            ) : (
              ""
            )}
            {props.estado !== "NAH" ? (
              <button onClick={() => handleEstado("NAH")}>NAH</button>
            ) : (
              ""
            )}
            {props.estado !== "NA" ? (
              <button onClick={() => handleEstado("NA")}>NA</button>
            ) : (
              ""
            )}
            {props.estado !== null ? (
              <button onClick={() => handleEstado(null)}>
                Eliminar filtro
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchAgencias;
