import React, { useState } from 'react';
import FormTarea from '../../common/FormTarea/FormTarea';

const NuevaTarea = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <button className="iniciar-visita pointer" onClick={openModal}>
        Nueva Tarea
      </button>
      <FormTarea
        isOpen={isOpen}
        onClose={onCloseModal}
        idCompany={props.idCompany}
        coordenadas={props.coordenadas}
        usersUMT={props.usersUMT}
        agencia={props.agencia}
      />
    </>
  );
};

export default NuevaTarea;
