import React, { useState } from "react";
const ResultadoVisita = (props) => {
  const handleResultado = (event) => {
    props.setResultado(event.target.value);
  };
  return (
    <div className="title-label">
      <h3 className="form-title">
        Resultado de la visita<small> *</small>
      </h3>
      <div className="resultado-container">
        <div>
          <input
            type="radio"
            id="COMPLETED"
            name="instalacion"
            value="COMPLETED"
            onChange={handleResultado}
          />
          <label for="COMPLETED">Completado</label>
        </div>
        <div>
          <input
            type="radio"
            id="CANCELED"
            name="instalacion"
            value="CANCELED"
            onChange={handleResultado}
          />
          <label for="CANCELED">Cancelado</label>
        </div>
        <div>
          <input
            type="radio"
            id="NO_SHOW"
            name="instalacion"
            value="NO_SHOW"
            onChange={handleResultado}
          />
          <label for="NO_SHOW">No se presento</label>
        </div>
      </div>
    </div>
  );
};

export default ResultadoVisita;
