import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import "./Urbano.css";
import Swal from "sweetalert2";
import Loader from "../../Loader/Loader";
import axios from "axios";
import url_local from "../../../config/config";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Verificar from "./Verificar";
import Editar from "./Editar";
import SolicitarMKT from "./SolicitudMKT";
import Cookies from "js-cookie";
const VerificarDireccion = (props) => {
  const [loading, setLoading] = useState(true);
  const [errorUbicacion, setErrorUbicacion] = useState(false);
  const [data, setData] = useState(false);
  const [editar, setEditar] = useState(false);
  const [pedido, setPedido] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(Cookies.get("contactoId") == "");
  const getDireccion = async () => {
    if (
      Cookies.get("contactoId") == null ||
      Cookies.get("contactoId") == undefined ||
      Cookies.get("contactoId") == ""
    ) {
      navigate("/home");
    } else {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const ubicacion = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          console.log(ubicacion);
          try {
            const response = await axios.get(
              url_local() + "/api/direccionUrbano/" + id
            );
            console.log(response.data);
            setData(response.data);
            setLoading(false);
          } catch (error) {
            console.log(error);
          }
        },
        function error() {
          setLoading(false);
          setErrorUbicacion(true);
        }
      );
    }
  };
  useEffect(() => {
    getDireccion();
  }, []);
  const activarEdicion = () => {
    setEditar(true);
  };
  const activarPedido = (data) => {
    setPedido(true);
    setData(data);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : errorUbicacion ? (
        Swal.fire({
          showCancelButton: true,
          showConfirmButton: false,
          html:
            '<iconify-icon icon="material-symbols:location-off-outline" style="color: #e75959;" width="100" height="100"></iconify-icon>' +
            "<br>" +
            "<h1>¡Uy!</h1>" +
            "<br>" +
            "     <h3>Parece que" +
            "<br>" +
            " ha ocurrido un error</h3>  " +
            "<br>" +
            "<h5>Por favor habilite su ubicación" +
            "<br>" +
            " para utilizar esta página</h5>",
          cancelButtonText: "Intentar de nuevo",
        }).then((result) => {
          if (result.isDismissed) {
            window.location.reload();
          }
        })
      ) : (
        <>
          <Navbar />
          <Verificar
            data={data}
            editar={activarEdicion}
            editarEstado={editar}
            pedidoEstado={activarPedido}
            pedido={pedido}
          />
          <Editar
            editarEstado={editar}
            data={data}
            pedidoEstado={activarPedido}
            pedido={pedido}
          />
          <SolicitarMKT
            pedido={pedido}
            data={data}
            id={id}
            setLoading={setLoading}
          />
        </>
      )}
    </>
  );
};

export default VerificarDireccion;
