import React from "react";
import telefono from "../../img/telefono.png";
import { Icon } from "@iconify/react";
import CoordenadasButton from "./CoordenadasButton";
import { formatDateNumber } from '../../common/Format';
const InformacionBox = (props) => {
 const direction = `${props.empresa.direccion_calle}, ${props.empresa.direccion_localidad} `;
  return (
    <>
      <h2>{props?.empresa.name}</h2>
      <div className="container_detail comapny-detail">
        {(() => {
          switch (props.empresa.estado_agencies.value) {
            case (props.empresa.estado_agencies.value = "AA"):
              return (
                <h1 style={{ background: "#25D366" }}>Estado: Activa (AA)</h1>
              );
            case (props.empresa.estado_agencies.value = "SA"):
              return <h1 style={{}}>Estado: Semi-Activa (SA)</h1>;
            case (props.empresa.estado_agencies.value = "SC"):
              return <h1 style={{}}>Estado: Solo Consulta (SC)</h1>;
            case (props.empresa.estado_agencies.value = "NAH"):
              return <h1 style={{}}>Estado: No activa ahora (NAH)</h1>;
            case (props.empresa.estado_agencies.value = "NA"):
              return <h1 style={{}}>Estado: No activa (NA)</h1>;
            case (props.empresa.estado_agencies.value = undefined):
              return <h1 style={{}}>Estado: Sin Estado</h1>;
            default:
              return <h1>Estado: Sin Estado</h1>;
          }
        })()}
        <p className="info">
          <Icon
            icon="mdi:car-insurance"
            width="30"
            height="30"
            color="#1a95d3"
          />
          {(() => {
            switch (props.empresa.estado_insurance.value) {
              case (props.empresa.estado_insurance.value = "AA"):
                return (
                  <p>
                    <span>Estado seguros:</span>Activa (AA)
                  </p>
                );
              case (props.empresa.estado_insurance.value = "SA"):
                return (
                  <p>
                    <span>Estado seguros:</span>Semi-Activa (SA)
                  </p>
                );
              case (props.empresa.estado_insurance.value = "SC"):
                return (
                  <p>
                    <span>Estado seguros:</span>Solo Consulta (SC)"
                  </p>
                );
              case (props.empresa.estado_insurance.value = "NAH"):
                return (
                  <p>
                    <span>Estado seguros:</span> No activa ahora (NAH)
                  </p>
                );
              case (props.empresa.estado_insurance.value = "NA"):
                return (
                  <p>
                    <span>Estado seguros:</span>No activa (NA)
                  </p>
                );
              case (props.empresa.estado_insurance.value = undefined):
                return (
                  <p>
                    <span>Estado seguros:</span>Sin Estado
                  </p>
                );
              default:
                return (
                  <p>
                    <span>Estado seguros:</span>Sin Estado
                  </p>
                );
            }
          })()}
        </p>
        <p className="info">
          <i className="fa-solid fa-calendar"></i>
            <p>
              <span>Ultimo ingreso a la web: </span>
              {props?.empresa?.ultimo_ingreso_web
                ? formatDateNumber(props?.empresa?.ultimo_ingreso_web)
                : 'Sin Registro'}
            </p>
        </p>
        <p className="info">
          <i className="fa-solid fa-tag"></i>
          {props.empresa.categoria_final.label == undefined ? (
            <p>
              <span>Producto:</span>No asignado
            </p>
          ) : (
            <p>
              <span>Producto:</span>
              {props.empresa.categoria_final.label}
            </p>
          )}
        </p>
        {props.empresa.phone == undefined ? (
          <p className="info">
            <img src={telefono} />
            <p>
              <span>Telefono: </span>Sin Telefono
            </p>
          </p>
        ) : (
          <p className="info">
            <a
              href={
                "https://api.whatsapp.com/send?phone=54" +
                props.empresa.phone
                  .replace("+", "")
                  .replace("0", "")
                  .replace(" ", "")
              }
            >
              {" "}
              <img src={telefono} />
              <p>
                <span>Telefono:</span>
                {props?.empresa.phone}
              </p>
            </a>
          </p>
        )}
        <p className="info">
          <Icon
            icon="ic:sharp-location-on"
            width="30"
            height="30"
            color="#1a95d3"
          />
            <p>
              <span>Direccion: </span> {direction}
            </p>
        </p>
        <div className="ubicacion">
          <CoordenadasButton id={props.id} loading={props.setLoading} />
          <a
            href={`https://www.google.com/maps/place/${props.empresa.latitud+','+props.empresa.longitud}`}
            className="location"
          >
            Ver la ubicacion
          </a>
        </div>
      </div>
    </>
  );
};

export default InformacionBox;
