import React, { useState } from "react";
const ServiciosContainer = (props) => {
  const [isChecked, setIsChecked] = useState(true);
  const checkverify = () => {
    props.setCheckboxCheker(!props.checkboxChecker);
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };
  const checkSeguros = async () => {
    if (props.personaContacto == undefined || props.personaContacto == "Otro") {
      props.notify(
        "Como selecciono OTRO no puede tildar los servicios adicionales"
      );
    } else {
      props.setSeguros(!props.seguros);
    }
  };
  /*
      
*/
  const checkTienda = async () => {
    if (props.personaContacto == undefined || props.personaContacto == "Otro") {
      props.notify(
        "Como selecciono OTRO no puede tildar los servicios adicionales"
      );
    } else {
      props.setTienda(!props.tienda);
    }
  };
  /*
   <div className="checkbox">
  <h4 className="title-label2"> ¿Solicito merchandising?</h4>
  <input
    type="checkbox"
    id="SiQuiero"
    name="SiQuiero"
    checked={props.checkboxChecker}
    onChange={() => checkverify()}
  />
</div>
  */
  return (
    <div className="adittional-services">
      <h2 className="form-title">Servicios Adicionales:</h2>

      <div className="checkbox">
        <h4 className="title-label2">¿Le interesa la venta de Seguros?</h4>
        <input
          type="checkbox"
          id="subscribe"
          checked={props.seguros}
          name="subscribe"
          onChange={() => checkSeguros()}
        />
      </div>
      <div className="checkbox">
        <h4 className="title-label2"> ¿Le interesa la Tienda?</h4>
        <input
          type="checkbox"
          id="subscribe"
          checked={props.tienda}
          name="subscribe"
          onChange={() => checkTienda()}
        />
      </div>
    </div>
  );
};

export default ServiciosContainer;
