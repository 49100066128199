import React from "react";
import AgenciaData from "./AgenciaData";
const AgenciaBox = (props) => {
  const f = props.f;
  return (
    <div className="Agencia-box" id="agencias-box">
      <AgenciaData empresa={f} />
    </div>
  );
};

export default AgenciaBox;
