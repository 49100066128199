import React, { useState } from "react";
import { useEffect } from "react";
//* Setea el estado para poder usar el select
const FileInput = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [promoOpen, setPromoOpen] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [promoValue, setPromoValue] = useState("Selecciona la Promo!");
  function handleOptionChange(event) {
    props.setPromo(event.target.value);
    setPromoValue(event.target.id);
    setPromoOpen(false);
  }

  //function handleFileChange(event) {
  // setSelectedFile(event.target.files[0]);
  //}

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // const base64Data = e.target.result;
        // setBase64Image(base64Data);
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800; // Define el ancho máximo que desees
          const maxHeight = 600; // Define el alto máximo que desees
          let width = image.width;
          let height = image.height;
          if (width > maxWidth || height > maxHeight) {
            if (width / maxWidth > height / maxHeight) {
              width = maxWidth;
              height = (width * image.height) / image.width;
            } else {
              height = maxHeight;
              width = (height * image.width) / image.height;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);
          const base64Data = canvas.toDataURL("image/jpeg", 0.7); // Cambia 'image/jpeg' y 0.7 según tus necesidades
          setBase64Image(base64Data);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //console.log("IMG",base64Image);

  useEffect(() => {
    props.archivo(selectedFile, base64Image);
  }, [selectedFile, base64Image]);

  return (
    <div className="ImagenesDiv">
      <h4 className="title-label2"> Carga Una imagen!</h4>
      <div className="image-upload">
        <label
          for="file-input"
          className={selectedFile === null ? "" : "imagenSubida"}
        >
          <img
            src={selectedFile === null ? "/archivoP.png" : "/archivoPV.png"}
            alt=""
          />
        </label>
        <input
          accept="image/*"
          type="file"
          className="imgFotos"
          id="file-input"
          onChange={handleFileChange}
        />
      </div>
      <p className="NombreArchivo">{selectedFile ? selectedFile.name : ""}</p>
      <div className={selectedFile === null ? "div-select" : "div-container"}>
        <div className="contactos-container">
          <h4 className="title-label" onClick={() => setPromoOpen(!promoOpen)}>
            {" "}
            {promoValue}{" "}
            {promoOpen ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </h4>
          <div
            className={`contactos-item-container ${promoOpen ? "open" : ""}`}
          >
            {" "}
            <div className="contactos-item">
              <input
                type="radio"
                name="Promo"
                id="Promo campeones"
                value="Promo campeones 01-01 al 28-02"
                onChange={handleOptionChange}
              />
              <div>
                <h3>
                  <label htmlFor="Promo campeones">Promo campeones </label>
                </h3>
              </div>
            </div>
            <div className="contactos-item">
              <input
                type="radio"
                name="Promo"
                id="Seguros"
                value="Seguros"
                onChange={handleOptionChange}
              />
              <div>
                <h3>
                  <label htmlFor="Seguros">Seguros </label>
                </h3>
              </div>
            </div>
            <div className="contactos-item">
              <input
                type="radio"
                name="Promo"
                id="Otro"
                value="Otro"
                onChange={handleOptionChange}
              />
              <div>
                <h3>
                  <label htmlFor="Otro">Otro</label>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FileInput;
