import { toast } from 'react-toastify';

const baseToast = (text, type) => {
  toast[type](text, {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};

const successToast = (text) => {
  baseToast(text, 'success');
};
const errorToast = (text) => {
  baseToast(text, 'error');
};

export { successToast, errorToast };
