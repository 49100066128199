import React, { useContext, useEffect, useState } from "react";
import AgencyContext from "../../context/AgencyContext";
import Navbar from "../Navbar/Navbar";
import "./MasInformacion.css";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import Loader from "../Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import ReunionesInformacion from "./ReunionesInformacion";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper";
import url_local from "../../config/config";
import ErrorUbicacion from "../ErrorUbicacion/ErrorUbicacion";
import InformacionBox from "./InformacionBox";
import SectionNegocios from "./SectionNegocios";
import Visitabuttons from "./Visitabuttons";
import ContactosSlider from "./ContactosSlider";
import ContactosSection from "./ContactosSection";
import NuevaTarea from "./NuevaTarea";
import { errorToast } from "../../common/Toast";
const MasInformacion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [errorUbicacion, setErrorUbicacion] = useState(false);
  const [empresa, setEmpresa] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersUMT, setUsersUMT] = useState([]);
  const [negocios, setNegocios] = useState([]);
  const [contactosSection, setContactosSection] = useState(false);
  const [contactosIndex, setContactosIndex] = useState(false);
  const { agencyNombre, setAgencyNombre } = useContext(AgencyContext); //////////////////////
  const getAgencias = async () => {
    try {
      const nombre = reactLocalStorage.get("nombre", true);
      const clave = reactLocalStorage.get("clave", true);
      if (nombre === true || clave === true) {
        navigate("/");
      }
      await getUsersUMT();
      const response = await axios({
        method: "get",
        url: url_local() + `/api/graphqlCompany/${id}`,
        responseType: "json",
      });

      setEmpresa(response.data);
      setNegocios(
        response.data.associations.deal_collection__company_to_deal.items
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //console.log("empresa", empresa.name);

  //Contexto para guardar nombre de agencia

  useEffect(() => {
    getAgencias();
  }, []);

  useEffect(() => {
    if (empresa.name) {
      //console.log("NOMBRE EMPRESA", empresa.name);
      setAgencyNombre(empresa.name);
    }
  }, [empresa.name]);

  //console.log("agencia contexto", agencyNombre);

  const getUsersUMT = async () => {
    try {
      const usersUmt = await axios.get(url_local() + "/api/users-utm");
      setUsersUMT(usersUmt.data);
    } catch (e) {
      errorToast("Ocurrio un error al consultar los usuarios en UMT");
      setUsersUMT([]);
    }
  };

  useEffect(() => {
    getAgencias();
  }, []);

  navigator.geolocation.getCurrentPosition(
    async function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    },
    function error() {
      setLoading(false);
      setErrorUbicacion(true);
    }
  );
  return (
    <>
      {loading ? (
        <Loader />
      ) : errorUbicacion ? (
        <ErrorUbicacion />
      ) : (
        <>
          <Navbar
            contactosSection={contactosSection}
            setContactosSection={setContactosSection}
          />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div
            className={`informacion-section ${contactosSection ? "open" : ""}`}
          >
            <p className="visita">
              {empresa.fecha === undefined
                ? "Aun no fue visitada"
                : "Ultima visita el " + empresa.fecha}
            </p>
            <div className="informaicon-container">
              <InformacionBox
                empresa={empresa}
                id={id}
                setLoading={setLoading}
              />
              <h2>Dueños y Vendedores</h2>
              <Swiper
                navigation={false}
                slidesPerView={3.5}
                spaceBetween={6}
                loop={false}
                modules={Navigation}
                className="mySwiper"
              >
                {empresa.associations.contact_collection__company_to_contact
                  .items
                  ? empresa.associations.contact_collection__company_to_contact.items
                      .sort((a, b) => {
                        if (a.cargo_sistema?.value === "dueno") {
                          return -1;
                        } else if (b.cargo_sistema?.value === "empleado") {
                          return 1;
                        } else {
                          return 0;
                        }
                      })
                      .map((contacto, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ContactosSlider
                              setContactosSection={setContactosSection}
                              setContactosIndex={setContactosIndex}
                              index={index}
                              contacto={contacto}
                            />
                          </SwiperSlide>
                        );
                      })
                  : ""}
              </Swiper>

              <h2>Últimas visitas</h2>
              <Swiper
                navigation={false}
                slidesPerView={1.2}
                spaceBetween={10}
                pagination={{
                  dynamicBullets: true,
                }}
                centeredSlides={true}
                loop={false}
                modules={[Pagination, Navigation]}
                className="mySwiper sim"
              >
                {empresa.associations
                  .engagement_collection__company_to_engagement.items
                  ? empresa.associations.engagement_collection__company_to_engagement.items.map(
                      (reunion, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ReunionesInformacion
                              reunion={reunion}
                              key={index}
                            />
                          </SwiperSlide>
                        );
                      }
                    )
                  : ""}
              </Swiper>
              {empresa.associations.engagement_collection__company_to_engagement
                .items.length === 0 ? (
                <h3>No hay visitas registradas</h3>
              ) : (
                ""
              )}
              <SectionNegocios
                id={id}
                negocios={negocios}
                setNegocios={setNegocios}
              />
              <Visitabuttons
                empresa={empresa}
                id={id}
                longitude={longitude}
                latitude={latitude}
              />
              <NuevaTarea
                idCompany={id}
                coordenadas={{ lat: latitude, lng: longitude }}
                usersUMT={usersUMT}
                agencia={empresa.name}
              />
            </div>
          </div>
          <ContactosSection
            longitude={longitude}
            latitude={latitude}
            contactos={
              empresa.associations.contact_collection__company_to_contact.items
            }
            contactosIndex={contactosIndex}
            contactosSection={contactosSection}
            setContactosSection={setContactosSection}
            setContactosIndex={setContactosIndex}
            empresa={empresa}
            id={id}
          />
        </>
      )}
    </>
  );
};

export default MasInformacion;
