import React, { useContext, useState, useEffect } from "react";
import AgencyContext from "../../context/AgencyContext";
import Moment from "moment";
import Cookies from "js-cookie";
import url_local from "../../config/config";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import _uniqBy from "lodash/uniqBy";

const SendForm = (props) => {
  const comentario = props.infoMail;
  const [horaActual, setHoraActual] = useState("");
  const { agencyNombre } = useContext(AgencyContext);

  //console.log("Micaela!", props); ////////////////////
  //console.log("Agencia nombre:", agencyNombre); ////////////////
  //console.log("imagen prop", props.fotoBase64);

  useEffect(() => {
    const getCurrentTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const formattedTime = `${hours}:${minutes}`;

      setHoraActual(formattedTime);
    };

    getCurrentTime();
  }, []);

  //Mentions:
  //console.log("archivo", props.archivos);

  const mentions = [];
  const emailPattern = /[^@\s]*@decreditos\.com/g; // Patrón para buscar correos electrónicos con "@decreditos.com"

  const matches = comentario.match(emailPattern);
  if (matches) {
    matches.forEach((match) => {
      mentions.push({
        id: match.trim(),
      });
    });
  }

  // console.log("array", mentions);
  // console.log("comentario", comentario);

  const navigate = useNavigate();
  const fecha = Moment().format("MM-DD-YYYY");
  const Notificaiconnn = () =>
    toast.success("Visita completada con éxito", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleSubmit = async (e) => {
    //console.log("aquiiii");
    //e.preventDefault();

    const newTask = async () => {
      try {
        await axios.post(url_local() + "/api/full-task", {
          body: "mica",
          mentions: mentions,
          tipo: "visita",
          comentario,
          agencyNombre,
          horaActual,
          imagen: props.fotoBase64,
        });

        console.log("Bien!");
      } catch (error) {
        console.log("Ocurrio un error");
      }
    };
    newTask();
  };

  const verificar = async () => {
    if (
      props.habelCon === undefined ||
      //props.Comentario === undefined ||
      props.Comentario === "" ||
      props.resultado === undefined
    ) {
      props.notify("Por favor complete todos los campos requeridos (*)");
    }
    //*Comparacion de archivos si no llega valido no deja avanzar en el envio
    else if (
      (props.archivos !== null && props.selectedOption === "") ||
      (props.archivos !== null && props.selectedOption === null) ||
      (props.archivos !== null && props.selectedOption === undefined) ||
      (props.archivos !== null &&
        props.selectedOption === "Promociones Disponibles") ||
      (props.Comentarioarchivos !== null && props.selectedOption === "Otros")
    ) {
      props.notify("Por favor seleccione una promoción");
    } else {
      try {
        if (props.habelCon !== "Otro") {
          Cookies.set("contactoId", props.habelCon, { expires: 1 });
        }
        Notificaiconnn();
        props.setLoading(true);
        const response = await axios({
          method: "get",
          url: url_local() + "/api/fecha/" + props.id + "/" + fecha,
          responseType: "json",
        });

        console.log("uno", response.data);
      } catch (error) {
        console.log(error);
      }
      if (props.archivos !== false) {
        const formData = new FormData();
        formData.append("file", props.archivos);
        formData.append(
          "data",
          JSON.stringify({ idComapny: props.id, promo: props.selectedOption })
        );
        fetch(url_local() + "/api/upload", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      try {
        const data = {
          datos: {
            ownerId: props.clave,
            contactIds: [props.habelCon],
            ownerIds: [props.clave],
            body: props.Comentario,
            startTime: reactLocalStorage.get("fechadeInicio"),
            endTime: Date.now(),
            title: "Visita",
            meetingOutcome: props.resultado,
            companyId: props.id,
            companyName: reactLocalStorage.get("nombreagencia"),
            comercial: reactLocalStorage.get("nombre"),
            mail: props.Email,
          },
        };

        const response = await axios.post(url_local() + "/api/prueba", data);
        console.log(response.data);
        reactLocalStorage.remove("nombreagencia");
        reactLocalStorage.remove("fechadeInicio");
        reactLocalStorage.remove("VisitaId");

        navigate("/home");
        await handleSubmit();

        if (props.checkboxChecker === true) {
          try {
            navigate("/urbano/" + props.id);
          } catch {}
        } else {
          //console.log("faltan datos");
        }
        if (props.seguros === true) {
          try {
            const response = await axios({
              method: "get",
              url: url_local() + "/api/seguros/" + props.habelCon,
              responseType: "json",
            });
            console.log(response.data);
          } catch (error) {
            console.log(error);
          }
        }
        if (props.tienda === true) {
          try {
            const response = await axios({
              method: "get",
              url: url_local() + "/api/tienda/" + props.habelCon,
              responseType: "json",
            });
            console.log(response.data);
          } catch (error) {
            console.log(error);
          }
        }
        if (props.tareaId.length > 0) {
          try {
            const data = {
              datos: {
                idarray: props.tareaId,
              },
            };
            const response = await axios.post(
              url_local() + "/api/tareas",
              data
            );
            console.log(response);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <button
      type="submit"
      className="finalizar-boton"
      id="end"
      onClick={verificar}
    >
      {props.checkboxChecker ? "Continuar" : "Finalizar Visita"}
    </button>
  );
};
export default SendForm;
