import React, { useState } from "react";
import KitItem from "./KitItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url_local from "../../../config/config";
import Cookies from "js-cookie";
import { reactLocalStorage } from "reactjs-localstorage";
import { useEffect } from "react";
const SolicitarMKT = (props) => {
  const [producto, setProducto] = useState([]);
  const productos = async () => {
    const response = await axios.get(url_local() + "/api/productosMKT");
    console.log(response.data);
    setProducto(response.data);
  };
  useEffect(() => {
    productos();
  }, []);
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const selectItems = (id) => {
    console.log(id);
    setItem(id);
  };
  const notify = () =>
    toast.error("Por favor, seleccione un producto ", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const pedido = () => {
    if (item == undefined) {
      notify();
    }
  };
  console.log(props.data);
  const urbano = async () => {
    props.setLoading(true);
    props.data.item = item;
    props.data.id = props.id;
    props.data.contactoId = Cookies.get("contactoId");
    props.data.comercialId = reactLocalStorage.get("clave");
    navigate("/success");
    const response = await axios.post(
      url_local() + "/api/envioMerchandising",
      props.data
    );
    console.log(response.data);
    Cookies.remove("contactoId");
  };

  return (
    <div className={`solicitud-container ${props.pedido ? "open" : ""}`}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="solicitud-title">
        <h3>Solicitud de merchandising</h3>
        <h4>Elegí un kit para enviar a la agencia</h4>
      </div>

      <div className="kit-item-container">
        {producto.map((objeto, index) => {
          return (
            <KitItem
              key={index}
              objeto={objeto}
              selectItems={selectItems}
              item={item}
            />
          );
        })}
      </div>
      {item !== undefined ? (
        <button className="pedido-button open" onClick={() => urbano()}>
          Confirmar pedido
        </button>
      ) : (
        <button onClick={() => pedido()} className="pedido-button">
          Confirmar pedido
        </button>
      )}
    </div>
  );
};

export default SolicitarMKT;
