import React, { useState } from "react";
import Moment from "moment";
const Negocio = ({ negocio }) => {
  //console.log(negocio)
  //console.log(Moment(negocio.fecha).format('l'))
  function capitalizeTheFirstLetterOfEachWord(words) {
    var separateWord = words.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  }
  const [accordionNegocio, setAccordionNegocio] = useState(false);
  return (
    <div
      className="negocios-card-container"
      onClick={() => setAccordionNegocio(!accordionNegocio)}
    >
      <div
        className={(() => {
          switch (negocio.dealstage.value) {
            case (negocio.dealstage.value = "pre-approved"):
              return "negocios-title-container  PA";
            case (negocio.dealstage.value = "approved"):
              return "negocios-title-container AP";
            case (negocio.dealstage.value = "conditional"):
              return "negocios-title-container CO";
            case (negocio.dealstage.value = "rejected"):
              return "negocios-title-container RE";
            case (negocio.dealstage.value = "desisted"):
              return "negocios-title-container  DE";
            case (negocio.dealstage.value = "prenda-uploaded"):
              return "negocios-title-container  PC";
            case (negocio.dealstage.value = "liquidated"):
              return "negocios-title-container  LI";
            default:
              return negocio.dealstage.value;
          }
        })()}
      >
        <h3>
          {capitalizeTheFirstLetterOfEachWord(
            negocio.dealname.substring(negocio.dealname.indexOf(","), 0)
          )}
        </h3>
        <p>
          {" "}
          {(() => {
            switch (negocio.dealstage.value) {
              case (negocio.dealstage.value = "pre-approved"):
                return "(PA)";
              case (negocio.dealstage.value = "approved"):
                return "(AP)";
              case (negocio.dealstage.value = "conditional"):
                return "(CO)";
              case (negocio.dealstage.value = "rejected"):
                return "(RE)";
              case (negocio.dealstage.value = "desisted"):
                return "(DE)";
              case (negocio.dealstage.value = "prenda-uploaded"):
                return "(PC)";
              case (negocio.dealstage.value = "liquidated"):
                return "(LI)";
              default:
                return negocio.dealstage.value;
            }
          })()}{" "}
        </p>
        {accordionNegocio ? (
          <i className="fa-solid fa-chevron-up"></i>
        ) : (
          <i className="fa-solid fa-chevron-down"></i>
        )}
      </div>
      <div
        className={`negocios-details-container ${
          accordionNegocio ? "open" : ""
        }`}
      >
        <div>
          <h3>
            {negocio.vehicle}, {negocio.year}{" "}
          </h3>
        </div>
        <div className="negocios-detail-item">
          {" "}
          <h4>Correo: {negocio.correo ?? 'No'}</h4>
          <h4>Teléfono: {negocio.telefono ?? 'No'}</h4>
          <h4>
            {negocio.dealname.substring(negocio.dealname.indexOf(",") + 1)}
          </h4>
          <h4>Datero Deudor: {negocio.daterocompleted.value}</h4>
          <h4>
            Estado:
            {(() => {
              switch (negocio.dealstage.value) {
                case (negocio.dealstage.value = "pre-approved"):
                  return " Pre-Aprobada";
                case (negocio.dealstage.value = "approved"):
                  return " Aprobada";
                case (negocio.dealstage.value = "conditional"):
                  return " Condicionada";
                case (negocio.dealstage.value = "rejected"):
                  return " Rechazada";
                case (negocio.dealstage.value = "desisted"):
                  return " Desisitida";
                case (negocio.dealstage.value = "prenda-uploaded"):
                  return " Prenda Confeccionada";
                case (negocio.dealstage.value = "liquidated"):
                  return " Liquidada";
                default:
                  return negocio.dealstage.value;
              }
            })()}
          </h4>
          <h4>Nro Op: {negocio.operation}</h4>
          <h4>Fecha de alta: {Moment(negocio.createdate).format("l")}</h4>
          <h4>Importe: ${negocio.amountrequested}</h4>
          <h4>Plazo aprobado: {negocio.quotes_aprubed ?? '0'}</h4>
          <h4>
            Reporte de aprobados:{' '}
            {negocio.show_report_approved ? (
              <a
                href={negocio.show_report_approved}
                target="_blank"
                rel="noreferrer"
              >
                Ver Reporte
              </a>
            ) : (
              'No'
            )}
          </h4>
        </div>
      </div>
    </div>
  );
};
export default Negocio;
