import React from 'react';
import './Popup.css';

const Popup = ({ onClose }) => {
  const handlePopupClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={handlePopupClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={onClose}
        >
          <path
            d="M19.2219 19.3396C18.5562 20.0053 17.4809 20.0053 16.8152 19.3396L11.8823 14.4067L6.94943 19.3396C6.28374 20.0053 5.20841 20.0053 4.54272 19.3396C3.87704 18.6739 3.87704 17.5986 4.54272 16.9329L9.47562 12L4.54272 7.0671C3.87704 6.40142 3.87704 5.32608 4.54272 4.6604C5.20841 3.99471 6.28374 3.99471 6.94943 4.6604L11.8823 9.59329L16.8152 4.6604C17.4809 3.99471 18.5562 3.99471 19.2219 4.6604C19.8876 5.32608 19.8876 6.40142 19.2219 7.0671L14.289 12L19.2219 16.9329C19.8705 17.5815 19.8705 18.6739 19.2219 19.3396Z"
            fill="#E7E5E5"
          />
        </svg>
        <h2>¡Mejoramos con cada click!</h2>
        <p>
          En Decreditos utilizamos cookies para optimizar tu experiencia y
          usabilidad en nuestros productos.
          <span> ¡Seguí navegando y ayudanos a mejorarla!</span>
        </p>
        <div className="lb">
          <a
            href="https://www.decreditos.com/politicas-de-privacidad"
            target="_blank"
            rel="noreferrer"
          >
            Políticas de cookies
          </a>
          <button onClick={onClose}>Aceptar</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
