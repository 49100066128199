import React from "react";
import user from "../../img/user-solid 3.png";
import userDueño from "../../img/userDueño.png";

const ContactosSlider = (props) => {
  let firstName = props.contacto.firstname;
  const spaceIndex = firstName?.indexOf(" ");
  if (spaceIndex !== -1) {
    firstName = firstName?.slice(0, spaceIndex);
  }
  function contactosSection() {
    props.setContactosSection(true);
    props.setContactosIndex(props.contacto.email);
  }
  return (
    <div className="contactos-slider" onClick={() => contactosSection()}>
      <img
        src={
          props.contacto.cargo_sistema?.label == "Empleado" ? user : userDueño
        }
        alt=""
      />
      <p>
        {firstName}{" "}
        {props.contacto.lastname
          ? props.contacto.lastname?.slice(0, 1) + "."
          : ""}
      </p>
      <p>
        {props.contacto.cargo_sistema?.label == "Empleado"
          ? "Vendedor"
          : props.contacto.cargo_sistema?.label}
      </p>
    </div>
  );
};

export default ContactosSlider;
