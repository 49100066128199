import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../Loader/Loader";
import Navbar from "../Navbar/Navbar";
import "./Formulario.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import url_local from "../../config/config";
import FormularioContainer from "./formularioUrbano/FormularioContainer";
import ErrorUbicacion from "../ErrorUbicacion/ErrorUbicacion";
import Contactos from "./Contactos";
import FormularioTareas from "./FormularioTareas";
import ResultadoVisita from "./ResultadoVistia";
import Comentarios from "./Comentarios";
import ServiciosContainer from "./ServiciosAdicionales/ServiciosContainer";
import SendForm from "./SendForm";
import EmailButton from "./EmailButton";
const Formulario = () => {
  const navigate = useNavigate();
  const [infoMail, setInfoMail] = useState(""); //////////
  const [infoMention, setInfoMention] = useState([]); //////////
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [habelCon, setHablecon] = useState();
  const [personaContacto, setPersonaContacto] = useState();
  const [Comentario, setComentario] = useState();
  const [mention, setMention] = useState();
  const [contactos, setContactos] = useState();
  const [resultado, setResultado] = useState();
  const [checkboxChecker, setCheckboxCheker] = useState(false);
  const [seguros, setSeguros] = useState(false);
  const [tienda, setTienda] = useState(false);
  const [tareas, setTareas] = useState([]);
  const [tareaId, setTareaId] = useState([]);
  const [errorUbicacion, setErrorUbicacion] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    "Promociones Disponibles"
  );
  const [Email, setEmail] = useState(false);
  const [fotoBase64, setFotoBase64] = useState("");
  //console.log(selectedOption);
  //console.log(archivos);
  const nombre = reactLocalStorage.get("nombre", true);
  const clave = reactLocalStorage.get("clave", true);

  const archivo = (selectedFile, base64Image) => {
    //console.log(selectedFile);
    setArchivos(selectedFile);
    setFotoBase64(base64Image);
  };
  if (nombre == true || clave == true) {
    navigate("/");
  }
  if (
    reactLocalStorage.get("fechadeInicio") == null ||
    reactLocalStorage.get("VisitaId") !== id
  ) {
    navigate("/home");
  }
  const getContactosAsociados = async () => {
    try {
      const response = await axios({
        method: "get",
        url: url_local() + "/api/associate/" + id,
        responseType: "json",
      });
      setContactos(response.data.idarray);
      const responsepuesta = await axios({
        method: "get",
        url: url_local() + "/api/companystasks/" + clave + "/" + id,
        responseType: "json",
      });
      //console.log(response.data);
      //console.log(response.data);
      setTareas(responsepuesta.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContactosAsociados();
  }, []);

  const notify = (text) =>
    toast.error(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const dataMail = (data) => {
    setInfoMail(data);
  };

  const dataMention = (data) => {
    setInfoMention(data);
  };

  //console.log("info", infoMail);
  //console.log("tienda", tienda);

  return (
    <>
      {loading ? (
        <Loader />
      ) : errorUbicacion ? (
        <ErrorUbicacion />
      ) : (
        <>
          <Navbar />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="container">
            <div className="form_container">
              <div className="formu">
                <h1 className="form-title">Registro de visita</h1>
                <div className="Sub-titulo">
                  <h4>
                    ¿Con quien hablaste? <small> *</small>{" "}
                  </h4>
                </div>
                <div className="form_content">
                  <Contactos
                    setHablecon={setHablecon}
                    setPersonaContacto={setPersonaContacto}
                    setSeguros={setSeguros}
                    setTienda={setTienda}
                    contactos={contactos}
                    personaContacto={personaContacto}
                    setCheckboxCheker={setCheckboxCheker}
                  />
                  <FormularioTareas
                    tareaId={tareaId}
                    tareas={tareas}
                    setTareaId={setTareaId}
                  />
                  <ResultadoVisita setResultado={setResultado} />

                  <Comentarios
                    setComentario={setComentario}
                    Comentario={Comentario}
                    dataMail={dataMail}
                    setMention={setMention}
                    dataMention={dataMention}
                  />

                  {/* <EmailButton Email={Email} setEmail={setEmail} /> */}
                  <ServiciosContainer
                    seguros={seguros}
                    setSeguros={setSeguros}
                    checkboxChecker={checkboxChecker}
                    setCheckboxCheker={setCheckboxCheker}
                    tienda={tienda}
                    setTienda={setTienda}
                    personaContacto={personaContacto}
                    notify={notify}
                  />
                  <div className="contenedorImg">
                    <FormularioContainer
                      setPromo={setSelectedOption}
                      archivo={archivo}
                    />
                  </div>
                </div>
                <SendForm
                  archivos={archivos}
                  selectedOption={selectedOption}
                  id={id}
                  tienda={tienda}
                  seguros={seguros}
                  tareaId={tareaId}
                  clave={clave}
                  habelCon={habelCon}
                  Comentario={Comentario}
                  resultado={resultado}
                  checkboxChecker={checkboxChecker}
                  notify={notify}
                  setLoading={setLoading}
                  Email={Email}
                  infoMail={infoMail}
                  infoMention={infoMention}
                  fotoBase64={fotoBase64}
                  //empresa
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Formulario;
