import React, { useContext } from "react";
import logo from "../../img/logo.png";
import campanita from "../../img/campanita.png";
import "./Navbar.css";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import login from "../../img/Avatar.png";
import UserContext from "../../context/UserContext";
import { Icon } from "@iconify/react";
const Navbar = (props) => {
  let route = window.location.pathname;
  const { tareasPage, setTareasPage, tareasAmount } = useContext(UserContext);
  const { id } = useParams();
  if (route == "/home") {
    return (
      <div className="nav-container">
        {tareasAmount > 0 ? (
          <div onClick={() => setTareasPage(!tareasPage)}>
            <img src={campanita} alt="" srcSet="" />
          </div>
        ) : (
          <Icon
            icon="mdi:bell-outline"
            width="34"
            height="34"
            onClick={() => setTareasPage(!tareasPage)}
          />
        )}
        <Link to={"/home"}>
          <img className="logo" src={logo} />
        </Link>
        <a href="/profile" className="loginbutton">
          <img src={login} alt="" />
        </a>
      </div>
    );
  } else if (route == "/agencia/" + id || route == "/success" || route == "/contacto-nuevo/" + id) {
    return (
      <div className="nav-container">
        {props.contactosSection ? (
          <div className="arrow-link">
            {" "}
            <AiOutlineLeft
              className="left-arrow"
              size={40}
              onClick={() => props.setContactosSection(false)}
            />{" "}
          </div>
        ) : (
          <Link to={"/home"} className="arrow-link">
            <AiOutlineLeft className="left-arrow" size={40} />
          </Link>
        )}

        <Link to={"/home"}>
          <img className="logo" src={logo} />
        </Link>
        <a href="/profile" className="loginbutton">
          <img src={login} alt="" />
        </a>
      </div>
    );
  } else if (route == "/formulario/" + id || route == "/urbano/" + id) {
    return (
      <div className="nav-container">
        <Link to={"/agencia/" + id} className="arrow-link">
          <AiOutlineLeft className="left-arrow" size={40} />
        </Link>
        <Link to={"/home"}>
          <img className="logo" src={logo} />
        </Link>
      </div>
    );
  } else if (route == "/profile") {
    return (
      <div className="nav-container-4">
        <Link to={"/home"}>
          <img className="logo" src={logo} />
        </Link>
      </div>
    );
  }
};
export default Navbar;
