import React from "react";
import Swal from "sweetalert2";

const ErrorUbicacion = () => {
  return Swal.fire({
    showCancelButton: true,
    showConfirmButton: false,
    html:
      '<iconify-icon icon="material-symbols:location-off-outline" style="color: #e75959;" width="100" height="100"></iconify-icon>' +
      "<br>" +
      "<h1>¡Uy!</h1>" +
      "<br>" +
      "     <h3>Parece que" +
      "<br>" +
      " ha ocurrido un error</h3>  " +
      "<br>" +
      "<h5>Por favor habilite su ubicación" +
      "<br>" +
      " para utilizar esta página</h5>",
    cancelButtonText: "Intentar de nuevo",
  }).then((result) => {
    if (result.isDismissed) {
      window.location.reload();
    }
  });
};

export default ErrorUbicacion;
