import axios from "axios";
import React, { useState } from "react";
import url_local from "../../config/config";
import Negocio from "./Negocios";
const SectionNegocios = (props) => {
  const [boton, setBoton] = useState();
  const getNegocios = async (estado) => {
    setBoton(estado);
    const negociosRespone = await axios({
      method: "get",
      url: url_local() + `/api/negEstado/${props.id}/${estado}`,
      responseType: "json",
    });
    props.setNegocios(negociosRespone.data);
  };
  return (
    <div className="negocios-section-container">
      <h2>Operaciones</h2>

      <div className="negocios-filtros-container">
        <div className="reset-filtro-container">
          <h4>Filtros </h4>
          <button
            className={`reset ${boton !== undefined ? "open" : ""}`}
            onClick={() => getNegocios("undefined")}
          >
            Eliminar filtros
          </button>
        </div>
        <div>
          <button
            className={boton == "pre-approved" ? "open" : ""}
            onClick={() => getNegocios("pre-approved")}
          >
            Pre-aprobada
          </button>
          <button
            className={boton == "approved" ? "open" : ""}
            onClick={() => getNegocios("approved")}
          >
            Aprobada
          </button>
          <button
            className={boton == "conditional" ? "open" : ""}
            onClick={() => getNegocios("conditional")}
          >
            Condicional
          </button>
          <button
            className={boton == "rejected" ? "open" : ""}
            onClick={() => getNegocios("rejected")}
          >
            Rechazada
          </button>
          <button
            className={boton == "desisted" ? "open" : ""}
            onClick={() => getNegocios("desisted")}
          >
            Desistida
          </button>
          <button
            className={boton == "liquidated" ? "open" : ""}
            onClick={() => getNegocios("liquidated")}
          >
            Liquidada
          </button>
          <button
            className={boton == "prenda-uploaded" ? "open" : ""}
            onClick={() => getNegocios("prenda-uploaded")}
          >
            Prenda Confeccionada
          </button>
        </div>
      </div>

      <div className="negocios-items">
        {props.negocios ? <h4>Ultimas prendas</h4> : ""}
        {props.negocios != 0
          ? props.negocios.map((negocio, index) => {
              return <Negocio negocio={negocio} key={index} />;
            })
          : "No hay Operaciones disponibles"}
      </div>
    </div>
  );
};

export default SectionNegocios;
