const dev = false;
const url_local = () => {
  if (dev === true) {
    return "http://localhost:8080";
  } else {
    return "";
  }
};

export default url_local;
