import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import url_local from "../../config/config";
import { errorToast, successToast } from '../../common/Toast';
import { getDistanciaMetros } from '../../common/Utils';
const TaskData = ({ task , latitude, longitude }) => {
  const [tareaCompletada, setTareCompletada] = useState(false);
  const tickets = task.associations?.ticket_collection__engagement_to_ticket ?? null;
  const hasLocation =
    tickets &&
    tickets.items &&
    Array.isArray(tickets.items) &&
    tickets.items.length;

  const setTask = async () => {
    try {
      if (hasLocation && latitude && longitude) {
        const coords = JSON.parse(
          task.associations.ticket_collection__engagement_to_ticket.items[0]
            .subject
        );
        const distancia = getDistanciaMetros(
          latitude,
          longitude,
          coords.lat,
          coords.lng
        );
        if (distancia > 600) {
          return errorToast('Usted esta lejos de la dirección.');
        } 
      }
      await axios({
         method: "get",
         url: url_local() + "/api/completar/" + task.hs_object_id,
         responseType: "json",
       });
       setTareCompletada(true);
       successToast('Tarea Completada con exito');
    } catch (error) {
      errorToast('Ocurrio un error al finalizar la tarea.')
    }
  };
  return (
    <div className={`tarea ${tareaCompletada ? "complete" : ""}`}>
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="tareas-home-title">
          {" "}
          {task?.hs_task_subject ? (
            <h3>{task.hs_task_subject}</h3>
          ) : (
            "No hay titulo de la tarea"
          )}
          {task?.hs_task_priority?.value ? (
            <p
              className={(() => {
                switch (task?.hs_task_priority?.value) {
                  case "HIGH":
                    return "Alta";
                  case "MEDIUM":
                    return "Mediana";
                  case "LOW":
                    return "Baja";
                  default:
                    return task?.hs_task_priority?.value;
                }
              })()}
            >
              {(() => {
                switch (task.hs_task_priority.value) {
                  case (task.hs_task_priority.value = "HIGH"):
                    return "Alta";
                  case (task.hs_task_priority.value = "MEDIUM"):
                    return "Media";
                  case (task.hs_task_priority.value = "LOW"):
                    return "Baja";
                  default:
                    return "No hay una prioridad asignada";
                }
              })()}
            </p>
          ) : (
            " "
          )}
        </div>
        <div className="tareas-home-details">
          <div className="tarea-home-asociaciones">
            {task.associations?.company_collection__engagement_to_company?.items &&
            task.associations?.company_collection__engagement_to_company?.items?.length > 0 ? (
              <h3>
                Agencia:{" "}
                {
                  task.associations?.company_collection__engagement_to_company
                    ?.items[0]?.name
                }
              </h3>
            ) : (
              "Agencia: No hay agencia asignada"
            )}
            {task.associations?.contact_collection__engagement_to_contact?.items &&
            task.associations?.contact_collection__engagement_to_contact?.items.length > 0 ? (
              <div>
                <i className="fa-solid fa-user"></i>
                <h4>
                  <span>Contacto: </span>
                  {
                    task.associations?.contact_collection__engagement_to_contact
                      ?.items[0]?.name
                  }
                </h4>{" "}
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-user"></i>
                <h4>
                  <span>Contacto: </span>
                  No hay contacto asignado
                </h4>{" "}
              </div>
            )}
          </div>
          <div className="tareas-home-body">
            <h4>Descripcion de la tarea</h4>
           {task?.hs_task_body ? (
              <div dangerouslySetInnerHTML={{ __html: task?.hs_task_body }} />
            ) : (
              <p>No hay descripcion de la tarea</p>
            )}
          </div>
        </div>
      </div>
      <div className="content-button">
        <div className="button-container mr-5">
          {task.associations?.company_collection__engagement_to_company?.items &&
          task.associations?.company_collection__engagement_to_company?.items.length > 0 && (
            <Link
              to={`/agencia/${task.associations?.company_collection__engagement_to_company?.items[0]?.hs_object_id}`}
            >
              Más información
            </Link>
          )}
        </div>
          <div className="complete-button">
            <button onClick={() => setTask()}>Completar Tarea</button>
          </div>
      </div>
    </div>
  );
};

export default TaskData;
