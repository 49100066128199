import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
const Visitabuttons = (props) => {
  const navigate = useNavigate();
  const notify = (text) =>
    toast.error(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const noti = () =>
    toast.success("Visita iniciada con éxito!!!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const getdate = async () => {
    if (
      props.latitude > props.empresa.latitud - 0.003 &&
      props.latitude < props.empresa.latitud - -0.003 &&
      props.longitude > props.empresa.longitud - 0.003 &&
      props.longitude < props.empresa.longitud - -0.003
    ) {
      noti();
      reactLocalStorage.set("fechadeInicio", Date.now());
      reactLocalStorage.set("VisitaId", props.id);
      reactLocalStorage.set("nombreagencia", props.empresa.name);
      navigate("/agencia/" + props.id);
    }
  };
  const errorExpaliner = () => {
    if (reactLocalStorage.get("fechadeInicio") !== undefined) {
      console.log(
        props.latitude > props.empresa?.latitud - 0.003,
        props.latitude < props.empresa?.latitud - -0.003,
        props.longitude > props.empresa?.longitud - 0.003,
        props.longitude < props.empresa?.longitud - -0.003,
        reactLocalStorage.get("fechadeInicio") == undefined,
        reactLocalStorage.get("VisitaId") == undefined
      );
      notify("Usted tiene otra visita iniciada, por favor terminela");
    } else if (
      props.latitude > !props.empresa.latitud - 0.003 ||
      props.latitude < !props.empresa.latitud - -0.003 ||
      props.longitude > !props.empresa.longitud - 0.003 ||
      props.longitude < !props.empresa.longitud - -0.003
    ) {
      notify("Usted esta lejos de la agencia");
    }
  };
  return (
    <>
      <button
        onClick={()=>
          reactLocalStorage.get("fechadeInicio") == undefined &&
          reactLocalStorage.get("VisitaId") == undefined
            ? props.latitude > props.empresa?.latitud - 0.001 &&
              props.latitude < props.empresa?.latitud - -0.003 &&
              props.longitude > props.empresa?.longitud - 0.003 &&
              props.longitude < props.empresa?.longitud - -0.003
              ? getdate()
              : errorExpaliner()
            : reactLocalStorage.get("VisitaId") == props.id
            ? navigate("/formulario/" + props.id)
            : errorExpaliner()
        }
        className={
          reactLocalStorage.get("fechadeInicio") == undefined &&
          reactLocalStorage.get("VisitaId") == undefined
            ? props.latitude > props.empresa?.latitud - 0.001 &&
              props.latitude < props.empresa?.latitud - -0.003 &&
              props.longitude > props.empresa?.longitud - 0.003 &&
              props.longitude < props.empresa?.longitud - -0.003
              ? "iniciar-visita"
              : "no-iniciar-visita"
            : reactLocalStorage.get("VisitaId") == props.id
            ? "visita-iniciada"
            : "no-iniciar-visita"
        }
      >
        {reactLocalStorage.get("fechadeInicio") == undefined &&
        reactLocalStorage.get("VisitaId") == undefined
          ? "Iniciar visita"
          : reactLocalStorage.get("VisitaId") == props.id
          ? "Finalizar visita"
          : "Visita iniciada en otra agencia"}
      </button>
    </>
  );
};

export default Visitabuttons;
