import React, { useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import _uniqBy from 'lodash/uniqBy';
import Modal from '../Modal/Modal';
import { errorToast, successToast } from '../../common/Toast';
import { reactLocalStorage } from 'reactjs-localstorage';
import url_local from '../../config/config';
import axios from 'axios';
import './FormTarea.css';

const FormTarea = (props) => {
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [loading, setLoading] = useState(false);
  const [userMentions, setUsersMentions] = useState([]);
  const [state, setState] = useState('');
  const users = Array.isArray(props.usersUMT) ? props.usersUMT : [];
  //console.log("Props FormTarea",props);  

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setState(newValue);
    setUsersMentions(getUsersFromTags(newValue));
    setDetail(newPlainTextValue);
  };

  const userMentionData = users.map((myUser) => ({
    id: myUser.email,
    display: `@${myUser.name} ${myUser.lastname}`
  }));

  const getUsersFromTags = (text) => {
    const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
    const allUserIds = tags.map((myTag) => {
      const tagData = myTag.slice(3, -2);
      const tagDataArray = tagData.split('||');
      return { id: tagDataArray[1], name: tagDataArray[2] };
    });
    return _uniqBy(allUserIds, (myUser) => myUser.id);
  };

  //console.log("dd",userMentions);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await newTask();
  };
  const newTask = async () => {
    try {
      const ownerId = reactLocalStorage.get('clave', true);
      setLoading(true);

      await axios.post(url_local() + '/api/full-task', {
        title,
        body: detail,
        ownerId,
        idCompany: props.idCompany,
        coordenadas: props.coordenadas,
        mentions: userMentions,
        agencia: props.agencia ?? null,
        imagen: ""
      });

      closeModal();
      successToast('Tarea creada exitosamente!');
      window.location.reload();
    } catch (error) {
      setLoading(false);
      errorToast('Ocurrio un error');
    }
  };

  const closeModal = () => {
    setTitle('');
    setDetail('');
    setUsersMentions([]);
    setState('');
    setLoading(false);
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={closeModal} width="w-25">
        <h2>Nueva Tarea</h2>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="mt-1">
            <label htmlFor="title">Título</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="input-base"
            />
          </div>
          <div className="mt-1">
            <label htmlFor="detail">Detalle</label>
            <MentionsInput
              id="detail"
              value={state}
              onChange={handleChange}
              placeholder="Use el simbolo @ para mencionar usuarios"
              className="mentions"
              style={customStyle}
              required
            >
              <Mention
                type="user"
                trigger="@"
                data={userMentionData}
                markup="@{{user||__id__||__display__}}"
              />
            </MentionsInput>
          </div>
          {loading ? (
            <div className="loading-container">
              <div className="loading-circle"></div>
            </div>
          ) : (
            <>
              <button className="contactButton btn-tarea" type="submit">
                Guardar
              </button>
              <button
                className="contactButton btn-tarea btn-cancel"
                type="button"
                onClick={closeModal}
              >
                Cancelar
              </button>
            </>
          )}
        </form>
      </Modal>
    </>
  );
};
const customStyle = {
  input: {
    height: 120,
    overflow: 'auto'
  },
  highlighter: {
    height: 120,
    overflow: 'hidden',
    boxSizing: 'border-box'
  }
};

export default FormTarea;
