import React, { useState } from "react";
const Contactos = (props) => {
  const [accordionContact, setAccordionContact] = useState(false);
  const handleHableCon = (event) => {
    props.setHablecon(event.target.value);
    if (event.target.value == "Otro") {
      props.setPersonaContacto(event.target.value);

      setAccordionContact(false);
      props.setSeguros(false);
      props.setTienda(false);
      props.setCheckboxCheker(false);
    } else {
      let persona = props.contactos.filter((x) => x.id == event.target.value);
      props.setPersonaContacto(persona[0].nombre + " " + persona[0].apellido);
      setAccordionContact(false);
    }
  };
  return (
    <div className="contactos-container">
      <h3
        className="title-label"
        onClick={() => setAccordionContact(!accordionContact)}
      >
        {props.personaContacto != undefined ? (
          props.personaContacto
        ) : (
          <div></div>
        )}
        {accordionContact ? (
          <i className="fa-solid fa-chevron-up"></i>
        ) : (
          <i className="fa-solid fa-chevron-down"></i>
        )}
      </h3>
      <div
        className={`contactos-item-container ${accordionContact ? "open" : ""}`}
      >
        {props.contactos
          ? props.contactos.map((contacto) => {
              return (
                <div className="contactos-item" key={contacto.id}>
                  <input
                    type="radio"
                    name="contactiD"
                    id={contacto.id}
                    value={contacto.id}
                    onChange={handleHableCon}
                  />
                  <div>
                    <h3>
                      <label htmlFor={contacto.id}>
                        {contacto.nombre} {contacto.apellido}
                      </label>
                    </h3>
                  </div>
                </div>
              );
            })
          : ""}
        <div className="contactos-item">
          <input
            type="radio"
            name="contactiD"
            id="otro"
            value="Otro"
            onChange={handleHableCon}
          />
          <div>
            <h3>
              <label htmlFor="otro">Otro</label>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactos;
